import React from 'react';

import './App.css';
import './core/styles/HengelTable.css';
import './core/styles/HengelTableNoResp.css';
import './core/styles/primereact-responsive.css';

import Routes from './routes';
import { ToastContainer } from 'react-toastify';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import { isTriengel } from './services/Handlers'

document.title = isTriengel ? "Mills" : "Hengel";

export default function App() {

  return (
    <React.Fragment>

      <ToastContainer hideProgressBar={true} />

      <Routes />

    </React.Fragment>
  );
}
