import React, { useState } from 'react';

import Loader from 'react-loader-spinner'

import login from '../../services/LoginService';

export default function LoginForm() {

    const [showSpinner, setShowSpinner] = useState(false);
    const [usuarioLogin, setUsuarioLogin] = useState({
        usuario: '',
        senha: ''
    });

    const handleUsuarioLogin = (event) => {
        event.persist();
        setUsuarioLogin(usuarioLogin => ({ ...usuarioLogin, [event.target.name]: event.target.value }));
    }

    const entrar = async (event) => {

        event.preventDefault();

        setShowSpinner(true);
        login(usuarioLogin.usuario, usuarioLogin.senha).then(response => {
            
            window.location.href = response;
        
        }).finally(() => setShowSpinner(false))

        setUsuarioLogin(usuarioLogin => ({ ...usuarioLogin, senha: '' }));

    }

    return (
        <form onSubmit={entrar}>

            <div className="group">
                <label className="label">Usuario</label>
                <input required type="text" className="input" name="usuario"
                    value={usuarioLogin.usuario} onChange={handleUsuarioLogin} />
            </div>

            <div className="group">
                <label className="label">Senha</label>
                <input required type="password" className="input" name="senha"
                    value={usuarioLogin.senha} onChange={handleUsuarioLogin} />
            </div>

            <div className="group">
                <input type="submit" className="button" value="Entrar" />
            </div>

            {
                showSpinner ?
                    <div id="cover-spin">
                        <div className="loadingSpinner">
                            <Loader type="Bars" color="#324ea8" height={100} width={100} />
                        </div>
                    </div>
                    : ''
            }

        </form>
    )
}