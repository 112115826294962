import { toast } from 'react-toastify';

import axiosApi from './api';
import { logout } from './LoginService';

export default function errorHandler(error, mensagemSistema) {

    let msg;

    if (error) {

        if (error.data.error === 'invalid_token' && error.status === 401) {

            msg = 'Sua sessão expirou!';
            logout();

        } else if (error.status >= 400 && error.status <= 499) {

            msg = 'Ocorreu um problema ao processar a sua solicitação';

            if (error.status === 403) {
                msg = 'Você não tem permissão para executar esta ação';
                window.location.href = '/home';
            }

            if (error.status === 401) {
                msg = 'Sessão não iniciada';
                logout();
            }

        } else if (error.status === 500 && mensagemSistema && error.data.message) {
            msg = error.data.message;
        } else {
            msg = 'Problema ao processar serviço remoto. Tente novamente.';
        }

    } else {
        msg = 'Sem Conexão Com O Servidor';
        logout();
    }

    toast.error('Erro: ' + msg);

}

export function validTokenHandler() {

    axiosApi().get('tokens/valid').then(response => {

        if (!response.data) logout();

    }).catch(() => logout());

}

export function permitHandler(nomePermicao) {

    var jwt = require('jsonwebtoken');
    const jsontoken = localStorage.getItem('token');

    if (jsontoken) {

        const token = jwt.decode(jsontoken);
        const roles = token.authorities;

        return roles.includes(nomePermicao) ? true : false;

    }

    return false;

}

export function jwtHandler(newToken) {

    try {

        var jwt = require('jsonwebtoken');

        const jsontoken = newToken ? newToken : localStorage.getItem('token');

        const token = jwt.decode(jsontoken);

        return token;

    } catch (error) {
        return null
    }

}

export function empresasPermitidasHandler(consilidado = false) {

    var opcoesLiberadas = consilidado ? [{ label: 'Consolidado', value: 'CONSOLIDADO' }] : [];

    if (permitHandler('biomonte')) {
        opcoesLiberadas.push({ label: 'Hengel Logística', value: 'BIOMONTE' })
    }
    if (permitHandler('hengel')) {
        opcoesLiberadas.push({ label: 'Hengel', value: 'HENGEL' })
    }
    if (permitHandler('ebp')) {
        opcoesLiberadas.push({ label: 'HTS', value: 'EBP' })
    }
    if (permitHandler('triengel')) {
        opcoesLiberadas.push({ label: 'Mills', value: 'TRIENGEL' })
    }
    if (permitHandler('bhlocacoes')) {
        opcoesLiberadas.push({ label: 'BH', value: 'BH' })
    }

    return opcoesLiberadas;

}

export const empresasEnum = {
    BIOMONTE: { label: 'Hengel Logística', value: 'BIOMONTE' },
    HENGEL: { label: 'Hengel', value: 'HENGEL' },
    EBP: { label: 'HTS', value: 'EBP' },
    TRIENGEL: { label: 'Mills', value: 'TRIENGEL' },
    BH: { label: 'BH', value: 'BH' },
    CONSOLIDADO: { label: 'Consolidado', value: 'CONSOLIDADO' }
}

export const tipoMovimento = [
    { label: 'Receita/Despesa', value: 'RECEITADESPESA' },
    { label: 'Receita', value: 'RECEITA' },
    { label: 'Despesa', value: 'DESPESA' },
];

export const isTriengel = window.location.href.includes('triengel');